import React, { useContext } from 'react';
import './style-rendered.css';
import Billing from './Billing';
import Generator from './Generator';
import Material from './Material';
import Containers from './Containers';
import SiteInfo from './SiteInfo';
const ConfirmForm = ({ values }) => {
  const getInputValue = (item) => values[item] || '';
  return (
    <React.Fragment>
      <main id="rendered">
        <div className="main-form">
          <form action="#" method="POST">
            <section className="section--header" data-name="header">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 d-none d-sm-block">
                    <div className="logo-holder"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="form--header">
                      <h3
                        style={{
                          color: 'black',
                          lineHeight: 1,
                          padding: 'none',
                          margin: '0 auto',
                          color: 'var(--cri-blue)',
                        }}
                      >
                        Cleanlites {values?.CleanlitesFacility}
                      </h3>
                      <h3
                        style={{
                          color: 'black',
                          padding: 'none',
                          color: 'var(--cri-grey)',
                        }}
                      >
                        Order For{' '}
                        {values?.orderType == 'orderType--pickup'
                          ? 'Pickup'
                          : 'Drop Off'}
                      </h3>

                      <p>www.cleanlites.com | p. 513-641-4155</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="row">
                <Billing getInputValue={getInputValue} />
                <Generator getInputValue={getInputValue} />
                <Material
                  getInputValue={getInputValue}
                  getUnits={() => 'units or lbs'}
                />
                <Containers getInputValue={getInputValue} />
                <SiteInfo getInputValue={getInputValue} />
              </div>
            </div>
          </form>
        </div>
      </main>
    </React.Fragment>
  );
};

ConfirmForm.propTypes = {};

export default ConfirmForm;
