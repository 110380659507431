import React from 'react';
import PropTypes from 'prop-types';

const Material = ({ getInputValue, getUnits, setInputValue }) => {
  const getRenderedValue = (name) => {
    if (getInputValue(name) === '') {
      return '';
    }
    return `${getInputValue(name)}`;
  };
  return (
    <section className="section--material" data-name="materials">
      <div className="material-section--title" data-name="Materials">
        Materials
      </div>

      <div className="material--lamps" data-name="Lamps">
        <div className="material--title">
          <h3>Lamps</h3>
        </div>
        <label>4ft & Under</label>
        <input
          readOnly
          type="text"
          name="4ftUnder"
          value={getRenderedValue('lamps__4ftUnder')}
          // onChange={(e) => setInputValue(e.target)}
          // placeholder={getRenderedValue("4ftUnder")}
        />
        <label>8ft & Over</label>
        <input
          readOnly
          type="text"
          name="8ftOver"
          value={getRenderedValue('lamps__8ftOver')}
        />
        <label>CFL</label>
        <input
          readOnly
          type="text"
          name="CFL"
          value={getRenderedValue('lamps__cfl')}
        />
        <label>Incandescent</label>
        <input
          readOnly
          type="text"
          name="incandescent"
          value={getRenderedValue('lamps__incandescent')}
        />
        <label>U-Bend</label>
        <input
          readOnly
          type="text"
          name="uBend"
          value={getRenderedValue('lamps__uBend')}
        />
        <label>HIDs</label>
        <input
          readOnly
          type="text"
          name="HIDs"
          value={getRenderedValue('lamps__HID')}
        />
        <label>LEDs</label>
        <input
          readOnly
          type="text"
          name="LEDs"
          value={getRenderedValue('lamps__LED')}
        />
        <label>Other</label>
        <input
          readOnly
          type="text"
          name="lampsOther"
          value={getRenderedValue('lamps__other')}
        />
      </div>
      <div className="material--ballast" data-name="Ballast">
        <div className="material--title">
          <h3>Ballast</h3>
        </div>
        <label>
          <span>Ballasts</span>
        </label>
        <input
          readOnly
          type="text"
          name="ballast__ballast"
          value={getRenderedValue('ballast__ballast')}
        />
        <label>Capacitors</label>
        <input
          readOnly
          type="text"
          name="ballasts__capacitors"
          value={getRenderedValue('ballast__capacitors')}
        />
        <label>
          <span>Other 1</span>
        </label>
        <input
          readOnly
          type="text"
          name="ballast__other1"
          value={getRenderedValue('ballast_other1')}
        />
        <label>Other 2</label>
        <input
          readOnly
          type="text"
          name="ballast__other2"
          value={getRenderedValue('ballast_other2')}
        />
      </div>

      <div className="material--batteries" data-name="Batteries">
        <div className="material--title">
          <h3>Batteries</h3>
        </div>
        <label>
          <span>Nickel Cadmium</span>
        </label>
        <input
          readOnly
          type="text"
          name="batteries__niCad"
          value={getRenderedValue('batteries__niCad')}
        />
        <label>Nickel Metal Hydride</label>
        <input
          readOnly
          type="text"
          name="batteries__niMh"
          value={getRenderedValue('batteries__niMh')}
        />
        <label>Alkaline</label>
        <input
          readOnly
          type="text"
          name="batteries__alkaline"
          value={getRenderedValue('batteries__alkaline')}
        />
        <label>Zinc Carbon</label>
        <input
          readOnly
          type="text"
          name="batteries__zincCarbon"
          value={getRenderedValue('batteries__zincCarbon')}
        />
        <label>
          <span>Zinc Air</span>
        </label>
        <input
          readOnly
          type="text"
          name="batteries__zincAir"
          value={getRenderedValue('batteries__zincAir')}
        />
        <label>Lead Acid Dry Cell</label>
        <input
          readOnly
          type="text"
          name="batteries__leadAcidDry"
          value={getRenderedValue('batteries__leadAcidDry')}
        />
        <label>Lead Acid Wet Cell</label>
        <input
          readOnly
          type="text"
          name="batteries__leadAcidWet"
          value={getRenderedValue('batteries__leadAcidWet')}
        />
        <label>Mercury</label>
        <input
          readOnly
          type="text"
          name="batteries__mercury"
          value={getRenderedValue('batteries__mercury')}
        />
        <label>Button Cell</label>
        <input
          readOnly
          type="text"
          name="batteries__buttonCell"
          value={getRenderedValue('batteries__buttonCell')}
        />
        <label>Lithium</label>
        <input
          readOnly
          type="text"
          name="batteries__lithium"
          value={getRenderedValue('batteries__lithium')}
        />
        <label>Lithium Ion</label>
        <input
          readOnly
          type="text"
          name="batteries__lithiumIon"
          value={getRenderedValue('batteries__lithiumIon')}
        />
        <label>Magnesium</label>
        <input
          readOnly
          type="text"
          name="batteries__magnesium"
          value={getRenderedValue('batteries__magnesium')}
        />
        <label>Co-Mingled</label>
        <input
          readOnly
          type="text"
          name="batteries__coMingled"
          value={getRenderedValue('batteries__coMingled')}
        />
        <label>Other 1</label>
        <input
          readOnly
          type="text"
          name="batteries__other1"
          value={getRenderedValue('batteries__other1')}
        />
        <label>Other 2</label>
        <input
          readOnly
          type="text"
          name="batteries__other2"
          value={getRenderedValue('batteries__other2')}
        />
      </div>
      <div className="material--eWaste" data-name="eWaste">
        <div className="material--title">
          <h3>eWaste</h3>
        </div>

        <label>
          <span>Monitors</span>
        </label>
        <input
          readOnly
          type="text"
          name="monitors"
          value={getRenderedValue('ewaste__monitors')}
        />
        <label>Keyboards</label>
        <input
          readOnly
          type="text"
          name="keyboards"
          value={getRenderedValue('ewaste__keyboards')}
        />
        <label>Hard Drives</label>
        <input
          readOnly
          type="text"
          name="hardDrives"
          value={getRenderedValue('ewaste__hardDrives')}
        />
        <label>Printers</label>
        <input
          readOnly
          type="text"
          name="printers"
          value={getRenderedValue('ewaste__printers')}
        />
        <label>Other 1</label>
        <input
          readOnly
          type="text"
          name="electronicsOther1"
          value={getRenderedValue('ewaste__other1')}
        />
        <label>Other 2</label>
        <input
          readOnly
          type="text"
          name="electronicsOther2"
          value={getRenderedValue('ewaste__other2')}
        />
      </div>
      <div className="material--specialty" data-name="Specialty">
        <div className="material--title">
          <h3>Specialty</h3>
        </div>
        <label>
          <span>Airbag Devices</span>
        </label>
        <input
          readOnly
          type="text"
          name="specialty__airbag"
          value={getRenderedValue('specialty__airbagDevices')}
        />
        <label>Solar Panels</label>
        <input
          readOnly
          type="text"
          name="specialty__solarPanels"
          value={getRenderedValue('specialty__solarPanels')}
        />
        <label>Seatbelt Pretensioners</label>
        <input
          readOnly
          type="text"
          name="specialty__seatbeltPretensioners"
          value={getRenderedValue('specialty__seatbeltPretensioners')}
        />
      </div>
      <div className="material--haz" data-name="Hazardous">
        <div className="material--title">
          <h3>Hazardous / Other</h3>
        </div>
        <label>
          <span>HG Devices</span>
        </label>
        <input
          readOnly
          type="text"
          name="HG_devices"
          value={getRenderedValue('haz__HGDevices')}
        />
        <label>Fixtures</label>
        <input
          readOnly
          type="text"
          name="HG_fixtures"
          value={getRenderedValue('haz__fixtures')}
        />
        <label>Toners</label>
        <input
          readOnly
          type="text"
          name="HG_toners"
          value={getRenderedValue('haz__toners')}
        />
        <label>Transformers</label>
        <input
          readOnly
          type="text"
          name="HG_transformers"
          value={getRenderedValue('haz__transformers')}
        />
        <label>PCB Containing</label>
        <input
          readOnly
          type="text"
          name="HG_pcbContaining"
          value={getRenderedValue('haz__PCBContaining')}
        />
        <label>Other 1</label>
        <input
          readOnly
          type="text"
          name="HG_other1"
          value={getRenderedValue('haz__other1')}
        />
        <label>Other 2</label>
        <input
          readOnly
          type="text"
          name="HG_other2"
          value={getRenderedValue('haz__other2')}
        />
        <label>Other 3</label>
        <input
          readOnly
          type="text"
          name="HG_other3"
          value={getRenderedValue('haz__other3')}
        />
        <label>Other 4</label>
        <input
          readOnly
          type="text"
          name="HG_other4"
          value={getRenderedValue('HG_other4')}
        />
      </div>
    </section>
  );
};

Material.propTypes = {};

export default Material;
