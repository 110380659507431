import '../App.css';
import '../css/animations.css';
import '../css/style.css';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from '@mui/material';
export const getValid = async (token) => {
  return await fetch('/api/verify', {
    headers: { authorization: `Bearer ${token}` },
  }).then((res) => {
    if (res.status === 200) {
      return true;
    } else return false;
  });
};
function App() {
  const history = useNavigate();
  const [pw, setPw] = useState('');
  const [badPw, setBadPw] = useState(false);
  const handleSignIn = () => {
    fetch(`/api/login`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ password: pw }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          const result = await res.json();
          localStorage.setItem('accessToken', result.token);
          history('/forms/all');
        } else {
          setPw('');
          setBadPw(true);
        }
      })
      .catch((err) => setBadPw(true));
  };

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      getValid(localStorage.getItem('accessToken')).then((valid) => {
        if (valid) {
          history('/forms/all');
        } else {
          localStorage.clear();
        }
      });
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        gap: '2rem',
        fontSize: '2rem',
        margin: 'auto',
      }}
    >
      <Card sx={{ p: 2, pb: 0, maxWidth: '400px' }}>
        <CardHeader
          title="Sign In"
          subheader="Sign in as a Cleanlites user to access the internal platform."
        ></CardHeader>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            minHeight: '300px',
            flexDirection: 'column',
            gap: '2rem',
            fontSize: '2rem',
            margin: 'auto',
          }}
        >
          <div>
            {badPw && (
              <Typography style={{ color: 'red' }}>Please Try Again</Typography>
            )}
            <TextField
              fullWidth
              variant="filled"
              style={{ textAlign: 'center', borderRadius: '50px' }}
              name="email"
              type="Password"
              placeholder="Password"
              value={pw}
              onChange={(e) => setPw(e.target.value)}
            />
          </div>
          <CardActions sx={{ flexDirection: 'column', gap: '5px' }}>
            <Button fullWidth variant="contained" onClick={handleSignIn}>
              Sign In
            </Button>
            <Link to="/forgot-password" style={{ width: '100%' }}>
              <Button fullWidth onClick={handleSignIn}>
                Forgot?
              </Button>
            </Link>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
}

export default App;
