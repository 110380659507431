import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import SingleForm from './pages/SingleForm';
import AllForms from './pages/AllForms';
import { createTheme, ThemeProvider } from '@mui/material';
import ForgotPassword from './pages/ForgotPassword';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/forms',

    children: [
      {
        path: '/forms/:formId',
        loader: ({ params, request }) => params.formId,
        element: <SingleForm />,
      },
      {
        path: '/forms/all',
        element: <AllForms />,
      },
    ],
  },
]);

const theTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(25, 148, 242)',
    },
    secondary: {
      main: 'rgb(80, 208, 0)',
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
