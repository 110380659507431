import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getValid } from 'src/pages/App';

export const useJwt = () => {
  const [tokenLoaded, setTokenLoaded] = useState(null);
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let accessToken;
    const queryToken = searchParams.get('accessToken');
    if (queryToken) {
      localStorage.setItem('accessToken', queryToken);
      accessToken = queryToken;
    } else {
      accessToken = localStorage.getItem('accessToken');
    }

    if (accessToken) {
      getValid(localStorage.getItem('accessToken')).then((valid) => {
        if (!valid) {
          console.log('not valid');
          localStorage.clear();
          history('/');
        }
        console.log('valid token');
        setTokenLoaded(true);
      });
    } else {
      console.log('no access token');
      localStorage.clear();
      history('/');
    }
  }, [history, searchParams]);

  return tokenLoaded;
};
