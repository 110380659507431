import React from 'react';
import { Button, LinearProgress } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import ConfirmForm from '../components/renderedform/ConfirmForm';
import useApiQuery from '../hooks/useApiQuery';
import { useJwt } from 'src/hooks/useJwt';
import { ErrorBoundary } from '../components/ErrorBoundary';
const SingleForm = () => {
  const { formId } = useParams();
  const loaded = useJwt();

  const { data: values, loading } = useApiQuery({
    path: loaded && formId ? `/forms/${formId}` : null,
  });
  const handlePrint = (event, url) => {
    event.preventDefault();
    window.open(url, 'PRINT', 'height=750,width=550');
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <ErrorBoundary>
      <div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4,1fr)',
            gap: '2rem',
            padding: '1rem 1rem',
            fontSize: '1.5rem',
            position: 'fixed',
            backgroundColor: 'white',
            width: '100%',
            zIndex: '1000',
          }}
        >
          <Link to="/forms/all">
            <Button fullWidth variant="contained">
              Back
            </Button>
          </Link>
          <Button fullWidth onClick={(_) => handlePrint(_, values.view_link)}>
            Print
          </Button>
          <a
            href={`mailto:${values?.billingEmail}?subject=Your Cleanlites Order for ${values?.billingCompany}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button fullWidth>Email Client</Button>
          </a>
          <a href={values.view_link} rel="noreferrer" target="_blank">
            <Button fullWidth variant="contained" color="secondary">
              View PDF
            </Button>
          </a>
        </div>
        <div style={{ height: '60px' }}></div>
        {values.isPdf ? (
          <a href={values.view_link} rel="noreferrer" target="_blank">
            <Button fullWidth variant="contained" color="secondary">
              View PDF
            </Button>
          </a>
        ) : (
          <ConfirmForm values={values} />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default SingleForm;
