import React, { useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { CircularProgress, IconButton } from '@mui/material';

import useApiQuery from 'src/hooks/useApiQuery';

const FormTable = ({ uuid, isUploaded }) => {
  const { error, loading, data } = useApiQuery({
    path: `/forms/get_print_link?formId=${uuid}${
      isUploaded ? '&isPdf=true' : ''
    }`,
  });

  // const pdfFile = `https://storage.googleapis.com/cl-order-forms/forms/${
  //   isUploaded ? 'uploaded/' : ''
  // }${encodeURI(uuid)}.pdf`;

  const handlePrint = (event, link) => {
    event.preventDefault();
    window.open(link, 'PRINT', 'height=750,width=550');
  };
  if (error) {
    return <div></div>;
  }
  if (loading || !data) {
    return (
      <div style={{ padding: '1rem' }}>
        {' '}
        <CircularProgress sx={{ height: '20px', width: '20px' }} size="small" />
      </div>
    );
  }
  return (
    <>
      <IconButton onClick={(e) => handlePrint(e, data.view_link)}>
        <PrintIcon color="secondary" />
      </IconButton>

      <object data={data.view_link} type="application/pdf" hidden></object>
    </>
  );
};

export default FormTable;
