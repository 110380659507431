import React from 'react';

// import { TimeFrom } from "../inputs/time-picker";
const SiteInfo = ({ getInputValue }) => {
  return (
    <section class="section--site-info" data-name="siteInfo">
      <div class="section--title">Site Info</div>
      <div class="container-fluid">
        <div class="row site-info--row">
          <div class="col-sm-6">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr 1fr 2fr',
                gap: '1rem',
                justifyContent: 'center',
              }}
            >
              <label htmlFor="time__from">Hours</label>
              <input value={getInputValue('hours__from')} readOnly />
              <label htmlFor="time__to">To</label>
              <input value={getInputValue('hours__to')} readOnly />
            </div>
            <input
              readOnly
              className=""
              name="heightRestrictions"
              value={getInputValue('heightRestrictions')}
            />
            <label style={{ marginLeft: '5px' }}>Height Restrictions</label>
            <input
              readOnly
              className=""
              name="noPallets"
              value={getInputValue('numPallets')}
            />
            <label style={{ marginLeft: '5px' }}># of Pallets</label>
            <input
              readOnly
              class=""
              name="estTotalWeight"
              value={getInputValue('estTotalWeight')}
            />
            <label style={{ marginLeft: '5px' }}>Est. Total Weight</label>
          </div>
          <div class="col-sm-6 site-info--checks">
            <label class="label-info" htmlFor="palletized">
              Items Palletized
            </label>
            <input
              readOnly
              type="checkbox"
              name="palletized"
              checked={getInputValue('palletized')}
            />

            <label class="label-info" htmlFor="liftGate">
              Lift Gate Needed
            </label>
            <input
              readOnly
              type="checkbox"
              name="liftGate"
              checked={getInputValue('liftGate')}
            />

            <label class="label-info" htmlFor="dock">
              Loading Dock Available
            </label>
            <input
              readOnly
              type="checkbox"
              name="dock"
              checked={getInputValue('dock')}
            />

            <label class="label-info" htmlFor="workersAvailable">
              Workers Available
            </label>
            <input
              readOnly
              type="checkbox"
              value="false"
              id="workersAvailable"
              name="workersAvailable"
              checked={getInputValue('workersAvailable')}
            />

            <label class="label-info" htmlFor="palletJack">
              Pallet Jack Available
            </label>
            <input
              readOnly
              type="checkbox"
              name="palletJack"
              checked={getInputValue('palletJack')}
            />

            <label class="label-info" htmlFor="forklift">
              Forklift Available
            </label>
            <input
              readOnly
              type="checkbox"
              name="forklift"
              checked={getInputValue('forklift')}
            />
          </div>
          <div class="col-md-12">
            <br />
            <textarea
              readOnly
              class="textarea"
              type="textarea"
              name="comments"
              rows="2"
              value={getInputValue('SpecialInstruction')}
            ></textarea>
          </div>
        </div>
      </div>
    </section>
  );
};

SiteInfo.propTypes = {};

export default SiteInfo;
