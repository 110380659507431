import React, { useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { CircularProgress, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import useApiQuery from 'src/hooks/useApiQuery';
import { GridArrowDownwardIcon } from '@mui/x-data-grid';

const DownloadButton = ({ params, isPdf }) => {
  const { error, loading, data } = useApiQuery({
    path: `/forms/get_print_link?formId=${params.row.id}${
      isPdf ? '&isPdf=true' : ''
    }`,
  });

  if (error) {
    return <div></div>;
  }
  if (loading || !data) {
    return (
      <div style={{ padding: '1rem' }}>
        {' '}
        <CircularProgress sx={{ height: '20px', width: '20px' }} size="small" />
      </div>
    );
  }
  return (
    <a
      href={`${data.view_link}`}
      target="_blank"
      rel="noreferrer"
      download={true}
    >
      <IconButton>
        <GridArrowDownwardIcon color="secondary" />
      </IconButton>
    </a>
  );
};

export default DownloadButton;
