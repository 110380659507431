import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Card,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
            gap: '2rem',
            fontSize: '2rem',
            margin: 'auto',
          }}
        >
          <Card sx={{ p: 2, pb: 0, maxWidth: '400px' }}>
            <CardHeader
              title="Sign In"
              subheader="Sign in as a Cleanlites user to access the internal platform."
            ></CardHeader>
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                minHeight: '300px',
                flexDirection: 'column',
                gap: '2rem',
                fontSize: '2rem',
                margin: 'auto',
              }}
            >
              <div></div>
              <CardActions sx={{ flexDirection: 'column', gap: '5px' }}>
                <Link to="/" style={{ width: '100%' }}>
                  <Button fullWidth>Home</Button>
                </Link>
              </CardActions>
            </CardContent>
          </Card>
        </div>
      );
    }

    return this.props.children;
  }
}
